<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/9 pr-2">
					<h6>스타터</h6>
					<vSelect v-model="opt.starterType" :clearable="false" :options="starterOpts" />
				</div>
				<div class="w-1/9 px-2">
					<h6>seq</h6>
					<b-form-input v-model="opt.startSeq" placeholder="from" v-on:keyup.enter="search" />
					<h6 class="text-center w-1/1">~</h6>
					<b-form-input v-model="opt.endSeq" placeholder="to" v-on:keyup.enter="search" />
				</div>
				<div class="w-1/9 px-2">
					<h6>등록일</h6>
					<datePicker placeholder="from" model="opt.startCreatedDate" />
					<h6 class="text-center w-1/1">~</h6>
					<datePicker placeholder="to" model="opt.endCreatedDate" />
				</div>
				<div class="w-1/9 px-2">
					<h6>딜 오픈일</h6>
					<datePicker placeholder="from" model="opt.dealStartDateFrom" />
					<h6 class="text-center w-1/1">~</h6>
					<datePicker placeholder="to" model="opt.dealStartDateTo" />
				</div>
				<div class="w-1/9 px-2">
					<h6>연장푸시</h6>
					<vSelect v-model="opt.isSendAlarm" :clearable="false" :options="pushOpts" />
				</div>
				<div class="w-1/9 px-2">
					<h6>연장여부</h6>
					<vSelect v-model="opt.isExtendedDeal" :clearable="false" :options="isExtendedOpts" />
				</div>
				<div class="w-1/9 px-2">
					<h6>검색기준</h6>
					<vSelect v-model="opt.searchKeyName" :clearable="false" :options="keywordOpts" />
				</div>
				<div class="w-1/6 px-2">
					<h6>검색어</h6>
					<b-form-input
						class=""
						v-model.trim="opt.searchKeyword"
						name="keyword"
						placeholder="검색어"
						v-b-tooltip.hover.html
						@keyup.enter="search"
						title="SEQ, IDX, 업체명, 브랜드명, 상품그룹명, 딜번호, 핫딜가, 콘텐츠 URL, 이름, 아이디, 등급만 검색가능"
					/>
				</div>
				<div class="w-1/12 pl-1 pr-10">
					<h6 class="block">&nbsp;</h6>
					<b-button class="mt-0.5 w-1/1" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>

		<b-card>
			<div class="flex-between mb-4">
				<div>
					<b-button @click="deleteCheckedItem" variant="gray">일괄 삭제</b-button>
					<!--<b-button class="ml-4" @click="contentsAddPopup.open()" variant="purple">콘텐츠 등록하기</b-button>-->
				</div>
				<div class="flex">
					<p class="mt-2 mr-1">광고 지원 여부</p>
					<b-form-checkbox class="mt-2 mr-2" v-model="isSupportAdvertisement" switch />
					<b-button @click="excelExport">엑셀 다운로드 요청</b-button>
				</div>
			</div>
			<tb :inf="inf" :res="res" checkBoxKey="dealContentsSeq" child="1">
				<template #child="{ parent }">
					<div class="flex-right">
						<b-form-input
							class="w-2/3"
							v-model.trim="parent.editDealContentsUrl"
							name="editDealContentsUrl"
							placeholder="변경할 콘텐츠 URL을 입력해주세요"
							@keyup.enter="editContentsUrl(parent, parent.editDealContentsUrl)"
						/>
						<b-button
							class="mx-2 px-10"
							@click="editContentsUrl(parent, parent.editDealContentsUrl)"
							size="sm"
							>변경
						</b-button>
						<b-button class="mr-2 px-10" @click="cancelEditDealContentsUrl(parent)" size="sm" variant="gray"
							>취소
						</b-button>
					</div>
				</template>
			</tb>
		</b-card>
		<contentsAddPopup :pr="ths" cName="contentsAddPopup" />
	</div>
</template>

<script>
import contentsAddPopup from 'pages/contentsAddPopup'

const now = new Date(),
	startCreatedDate = getMonth(now, -1),
	endCreatedDate = now,
	starterOpts = [
		{ label: '전체', value: '' },
		{ label: '일반', value: 'completed' },
		{ label: '스타터', value: 'starter' },
	],
	//연장푸쉬여부
	pushOpts = [
		{ label: '전체', value: '' },
		{ label: '발송', value: 'push' },
		{ label: '미발송', value: 'no-push' },
	],
	//연장 여부
	isExtendedOpts = [
		{ label: '전체', value: '' },
		{ label: '해당없음', value: 'not-matched' },
		{ label: '연장', value: 'extended' },
		{ label: '미연장', value: 'un-extended' },
	],
	keywordOpts = [
		{ label: 'seq', value: 'seq' },
		{ label: 'idx', value: 'idx' },
		{ label: '업체명', value: 'company-name' },
		{ label: '브랜드명', value: 'brand-name' },
		{ label: '상품그룹명', value: 'product-group-name' },
		{ label: '딜번호', value: 'deal-num' },
		{ label: '핫딜가', value: 'saleprice' },
		{ label: '콘텐츠 URL', value: 'deal-contents-url' },
		{ label: '이름', value: 'member-name' },
		{ label: '아이디', value: 'hott-id' },
		{ label: '등급', value: 'member-class' },
	],
	defOpt = {
		starterType: starterOpts[0],
		startSeq: '',
		endSeq: '',
		//startCreatedDate: startCreatedDate,
		//endCreatedDate: endCreatedDate,
		startCreatedDate: '',
		endCreatedDate: '',
		isSendAlarm: pushOpts[0],
		dealStartDateFrom: '',
		dealStartDateTo: '',
		isExtendedDeal: isExtendedOpts[0],
		searchKeyName: keywordOpts[0],
		searchKeyword: '',
	}

export default {
	components: { contentsAddPopup },
	data() {
		return {
			starterOpts,
			pushOpts,
			isExtendedOpts,
			keywordOpts,
			opt: { ...defOpt },
			defOpt,
			isSupportAdvertisement: true,

			inf: [
				{ model: 'checkAll' },
				{ title: 'seq', key: 'dealContentsSeq', size: 3 },
				{ title: 'main seq', key: 'instaPostingSeq', size: 4 },
				{ title: 'idx', key: 'productGroupIdx', size: 3 },
				{ title: '업체명', key: 'companyName', size: 6, notCenter: 1 },
				{ title: '브랜드명', key: 'brandName', size: 6, notCenter: 1 },
				{ title: '상품 그룹명', key: 'productGroupName', size: 6, notCenter: 1 },
				{ title: '딜번호', key: 'dealNum', size: 3 },
				{ title: '스타터', key: 'isStarterComplete', size: 3 },
				{ title: '핫딜가', key: 'salePrice', size: 3 },
				{ title: '딜형태', key: 'dealTypeName', size: 3 },
				{ title: '콘텐츠 URL', key: 'dealContentsUrl', size: 6, notCenter: 1 },
				{ title: '이름', key: 'memberName', size: 4 },
				{ title: '아이디', key: 'hottId', size: 3 },
				{ title: '등급', key: 'hottistClass', size: 2 },
				{ title: '등록일', key: 'createDateTime', size: 6 },
				{ title: '딜 오픈일', key: 'dealStartDate', size: 6 },
				{ title: '딜 종료일', key: 'dealEndDate', size: 6 },
				{ title: '연장푸시', key: 'isSendAlarm', size: 4 },
				{ title: '연장여부', key: 'isDealExtension', size: 6 },
				{ title: '영상 오류', key: 'isMediaEmpty', size: 3 },
				{ title: '수집 오류', key: 'isInvalidContents', size: 3 },
				{
					title: '숨김',
					model: 'button',
					size: 4,

					vIf: item => item.saveStatus,
					text: item => (item.saveStatus == 'Y' ? '숨김' : '해제'),
					bSize: 'sm',
					bVariant: item => (item.saveStatus == 'Y' ? 'primary' : 'gray'),
					func: this.toggleStatus,
				},
				{
					title: '삭제',
					model: 'button',
					icon: 'trash',
					size: 2,
					func: this.deleteItem,
				},
				/*{
					title: '수정/삭제',
					model: 'editDelete',
					size: 5,
					func1: item => {
						item.editDealContentsUrl = ''
						item.child = true
					},
					func2: this.deleteItem,
				},*/
			],
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		isOverOneMonth(start, end) {
			if (!start || !end) return -1
			start = new Date(start)
			end = new Date(end)

			const beforeOneMonth = getMonth(end, -1)
			return start < beforeOneMonth
		},
		search() {
			const opt = this.opt,
				//isOverCreatedDate = this.isOverOneMonth(opt.startCreatedDate, opt.endCreatedDate),
				//isOverDealDate = this.isOverOneMonth(opt.dealStartDateFrom, opt.dealStartDateTo),
				starterType = getDropdownValue(opt.starterType),
				isSendAlarm = getDropdownValue(opt.isSendAlarm),
				isExtendedDeal = getDropdownValue(opt.isExtendedDeal),
				error = [],
				data = {
					paging: getPaging(),
				}

			if (opt.startSeq || opt.endSeq) {
				if (opt.startSeq && opt.endSeq) {
					if (!isNum(opt.startSeq) || !isNum(opt.endSeq)) error.push('seq는 숫자만 입력 가능합니다')
					else if (opt.startSeq * 1 > opt.endSeq * 1) error.push('startSeq가 endSeq보다 큽니다')
					else {
						data.startSeq = opt.startSeq
						data.endSeq = opt.endSeq
					}
				} else error.push('seq 범위를 정확히 입력해주세요')
			}

			if (opt.startCreatedDate && opt.endCreatedDate) {
				if (opt.startCreatedDate > opt.endCreatedDate) error.push('등록일의 시작일이 종료일보다 큽니다')
				else {
					data.startCreatedDate = opt.startCreatedDate.get_time('d')
					data.endCreatedDate = opt.endCreatedDate.get_time('d')
				}
			}
			if (opt.dealStartDateFrom && opt.dealStartDateTo) {
				if (opt.dealStartDateFrom > opt.dealStartDateTo) error.push('딜 오픈일의 시작일이 종료일보다 큽니다')
				else {
					data.dealStartDateFrom = opt.dealStartDateFrom.get_time('d')
					data.dealStartDateTo = opt.dealStartDateTo.get_time('d')
				}
			}
			//if (isOverCreatedDate > 0 || isOverDealDate > 0)
			//error.push('등록일 또는 딜 오픈일의 시작일과 종료일은 1개월 이내로 입력해주세요.')

			if (starterType) data.starterType = starterType
			if (isSendAlarm) data.isSendAlarm = isSendAlarm
			if (isExtendedDeal) data.isExtendedDeal = isExtendedDeal

			if (opt.searchKeyword) {
				data.searchType = getDropdownValue(opt.searchKeyName)
				data.searchKeyword = opt.searchKeyword
			}

			if (error.length) alert.w(error)
			else {
				this.lastOpt = data
				this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1
			if (ex) this.lastOpt.isSupportAdvertisement = this.isSupportAdvertisement
			else delete this.lastOpt.isSupportAdvertisement

			postApi('/dealSelect/dealContentsList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						v.child = false
						if (v.isInvalidContents) {
							v.isInvalidContents = '오류'
							v.trClass = 'text-danger'
						} else v.isInvalidContents = ''

						if (v.isMediaEmpty) {
							v.isMediaEmpty = '오류'
							v.trClass = 'text-danger'
						} else v.isMediaEmpty = ''

						if (v.dealTypeName == '내돈내산 딜') v.trClass = 'table-info'

						v.salePrice = comma(v.salePrice)
						v.dealContentsUrl = `<a href="${v.dealContentsUrl}" target='_blank'>${v.dealContentsUrl}</a>`
						v.isSendAlarm = v.isSendAlarm ? '발송' : '미발송'

						v.isStarterComplete = v.isStarterComplete ? '일반' : '스타터'

						v.createDateTime = v.createDateTime.replace('T', ' ')
						;['dealStartDate', 'dealEndDate'].map(k => (v[k] = v[k] ? v[k].get_time('d') : ''))
						return v
					})
					this.res = res
					return res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()
			ex.dropdown(opt.starterType, this.starterOpts, '스타터', '전체')
			if (opt.startSeq) ex.key('seq', `${opt.startSeq}~${opt.endSeq}`)
			ex.date(opt.startCreatedDate, opt.endCreatedDate, '등록일')
			ex.date(opt.dealStartDateFrom, opt.dealStartDateTo, '딜 오픈일')
			ex.dropdown(opt.isSendAlarm, this.pushOpts, '연장푸시', '전체')
			ex.dropdown(opt.isExtendedDeal, this.isExtendedOpts, '연장여부', '전체')
			ex.search({ key: opt.searchType, val: opt.searchKeyword }, keywordOpts)
			ex.key('광고 지원 여부', this.isSupportAdvertisement ? 'O' : 'X')
			ex.go(this)
		},
		deleteItem(item) {
			if (!Array.isArray(item)) item = [item.dealContentsSeq]
			confirm('삭제 시 핫딜 모아보기에서도 함께 삭제 됩니다.\r\n삭제하시겠습니까 ?', () => {
				postApi('/dealHandle/deleteDealContents', {
					//얘는 또 왜 포스트지..
					dealContentsSeq: item,
				}).then(() => {
					reload(this)
					alert.s('삭제되었습니다')
				})
			})
		},
		deleteCheckedItem() {
			const items = this.tb.getChecked('dealContentsSeq')
			if (!items.length) alert.w('선택된 콘텐츠가 없습니다.')
			else this.deleteItem(items)
		},
		toggleStatus(item) {
			let confirmText, status
			if (item.saveStatus == 'Y') {
				confirmText =
					'콘텐츠를 숨김처리 합니다.\r\n핫딜 모아보기에서 노출중인 콘텐츠라면 숨김처리 시 목록에서 삭제됩니다.\r\n계속하시겠습니까?'
				status = 'N'
			} else {
				confirmText =
					'콘텐츠 숨김상태를 해제합니다.\r\n내일부터 핫딜 모아보기에 노출될 수 있습니다.\r\n계속하시겠습니까?'
				status = 'Y'
			}

			confirm(confirmText, () => {
				putApi('/dealHandle/hideOnOffDealContents', {
					instaPostingSeq: item.instaPostingSeq,
					saveStatus: status,
				}).then(() => {
					reload(this)
				})
			})
		},
		getUrlTvToP(url) {
			return url.replace(/\/tv\//, '\/p\/')
		},
		editContentsUrl(item, url) {
			if (isUrl(url)) {
				url = this.getUrlTvToP(url)
				confirm(
					`seq: ${item.dealContentsSeq}\r\n딜 번호: ${item.dealNum}\r\n${item.companyName}\r\n${item.productGroupName}\r\n\r\n의 콘텐츠 URL을 \r\n${url}\r\n로 변경하시겠습니까?`,
					() => {
						putApi('/dealHandle/updateDealContents', {
							dealContentsSeq: item.dealContentsSeq,
							dealContentsUrl: url,
						}).then(() => {
							alert.s('수정이 완료되었습니다.')
							item.dealContentsUrl = `<a href='${url}'>${url}</a>`
							item.child = false
						})
					}
				)
			} else alert.w('URL 형식이 아닙니다.\r\n변경하려는 URL을 확인해주세요.')
		},
		cancelEditDealContentsUrl(item) {
			item.child = false
		},
	},
	created() {
		this.search()
	},
}
</script>
