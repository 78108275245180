<template>
	<basePopup :okFunc="save" okText="확인" title="콘텐츠 등록">
		<div v-for="(v, i) in addList" class="flex my-2">
			{{ i + 1 }}. 딜번호
			<b-form-input
				:class="v.isError ? 'border-danger' : ''"
				class="w-1/6 -top-1.5 mx-3"
				v-model.trim="v.dealNum"
				name="dealNum"
				placeholder="딜번호를 입력해주세요"
				@keyup.enter="save"
			/>
			url
			<b-form-input
				:class="v.isUrlError ? 'border-danger' : ''"
				class="w-1/2 -top-1.5 ml-3"
				v-model.trim="v.url"
				name="url"
				placeholder="url을 입력해주세요"
				@keyup.enter="save"
			/>
			<b-button class="-top-1.5 ml-3 px-4" @click="removeList(i)" variant="gray">-</b-button>
			<b-button v-if="i + 1 == allLength" class="-top-1.5 ml-3 px-4" @click="addList.push({ ...defaultList })"
				>+</b-button
			>
		</div>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
export default {
	components: { basePopup },
	data() {
		const defaultList = { dealNum: '', url: '', isError: false, isUrlError: false }
		return {
			isShow: false,
			defaultList,
			addList: [{ ...defaultList }],
		}
	},
	methods: {
		open() {
			this.addList = [{ ...this.defaultList }]
			this.isShow = true
		},
		displayError(idx = 0, isDealNum = false, isUrl = false) {
			this.addList[idx].isError = isDealNum
			this.addList[idx].isUrlError = isUrl
		},
		save() {
			const data = []
			let isError = false
			this.addList.map((v, i) => {
				v.url = this.p.getUrlTvToP(v.url)
				this.displayError(i)
				if (v.dealNum || v.url) {
					if (v.dealNum && v.url) {
						if (isUrl(v.url)) data.push({ dealNum: v.dealNum, dealContentsUrl: v.url })
						else {
							this.displayError(i, false, true)
							isError = true
						}
					} else {
						this.displayError(i, !v.dealNum, !isUrl(v.url))
						isError = true
					}
				}
				return v
			})
			if (!isError) {
				if (data.length) {
					confirm(`총 ${data.length}개의 콘텐츠를 등록하시겠습니까?`, () => {
						postApi('/dealHandle/insertDealContents', data, false, false).then(res => {
							if (res.success == '6000') {
								res.data.map(errorNum => {
									this.addList.map((v, i) => {
										if (v.dealNum == errorNum) this.displayError(i, true)
									})
								})
								alert.w('콘텐츠 등록에 실패했습니다.\r\n표시된 딜 번호를 확인해주세요.')
							} else {
								alert.s('등록되었습니다.')
								this.isShow = false
								reload(this, true)
							}
						})
					})
				} else alert.w('입력된 항목이 없습니다')
			}
		},
		removeList(idx) {
			if (this.allLength != 1) this.addList.splice(idx, 1)
		},
	},
	computed: {
		allLength() {
			return this.addList.length
		},
	},
}
</script>
